import { App } from 'vue';

import translate from '@/utils/i18n/index';

export default {
    install: (app: App) => {
        app.config.globalProperties.$translate = translate;
    },
};

declare module '@vue/runtime-core' {
    interface ComponentCustomProperties {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        $translate: (key: string, domain?: string, params?: {[key: string]: any}) => string;
    }
}
