import { App } from 'vue';

import { globals, ShopId } from '@/types/globals';

export default {
    install: (app: App) => {
        app.config.globalProperties.$globals = globals;
        app.config.globalProperties.$isShopHtde = globals.shopId === ShopId.HTDE;
    },
};

declare module '@vue/runtime-core' {
    interface ComponentCustomProperties {
        $globals: typeof globals;
        $isShopHtde: boolean;
    }
}
